import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = { item: String };

  connect() {}

  favorite() {
    const link = $(this.element).find(".favorite");

    if (link.hasClass("no")) {
      $.ajax({
        url: `/scrapbook/add_recipe?recipe_id=${this.itemValue}`,
        type: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        },
        success: () => {
          link.removeClass("no");
          link.addClass("yes");
          link.attr("title", "click to remove this from your scrapbook");
        },
      });
    } else {
      $.ajax({
        url: `/scrapbook/remove_recipe?recipe_id=${this.itemValue}`,
        type: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        },
        success: () => {
          link.removeClass("yes");
          link.addClass("no");
          link.attr("title", "click to add this to your scrapbook");
        },
      });
    }
  }
}
