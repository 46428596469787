import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggle"];

  connect() {}

  toggle() {
    if (this.toggleTarget.style.display === "none") {
      this.toggleTarget.style.display = "block";
    } else {
      this.toggleTarget.style.display = "none";
    }
  }
}
