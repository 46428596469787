import { Controller } from "stimulus";

export default class extends Controller {
  static values = { network: String };

  connect() {}

  share() {
    if (this.networkValue === "facebook") {
      this.facebook();
    } else {
      this.twitter();
    }
  }

  facebook() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" +
        escape(window.location.href) +
        "&t=" +
        document.title,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
    );
    return false;
  }

  twitter() {
    const u = window.location.href;
    const t = document.title;
    window.open(
      "https://twitter.com/share?url=" +
        encodeURIComponent(u) +
        "&text=" +
        encodeURIComponent(t),
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
    );
    return false;
  }
}
