import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["destination", "template"];

  static values = { wrapper: String };

  connect() {}

  insert() {
    $(this.destinationTarget).show();
    $(this.destinationTarget).append(
      $(this.templateTarget).html().replace(/-1/g, new Date().getTime())
    );
  }

  remove(event) {
    const wrapper = $($(event.target).parents(`.${this.wrapperValue}`));
    const destroyField = $(wrapper.find("input[name*='_destroy']"));
    destroyField.val(true);
    wrapper.hide();
  }
}
